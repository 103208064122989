import { LitElement, css, html } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';

// import { serialize } from '@shoelace-style/shoelace/dist/utilities/form.js';

import { styles as sharedStyles } from '../styles/shared-styles'

import '../components/date-selector.js';

import '@shoelace-style/shoelace/dist/components/card/card.js';
import '@shoelace-style/shoelace/dist/components/avatar/avatar.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/animation/animation.js';
import '@shoelace-style/shoelace/dist/components/tab-panel/tab-panel.js';
import '@shoelace-style/shoelace/dist/components/tab/tab.js';
import '@shoelace-style/shoelace/dist/components/tab-group/tab-group.js';
import '@shoelace-style/shoelace/dist/components/details/details.js';
import '@shoelace-style/shoelace/dist/components/input/input.js';

import '@shoelace-style/shoelace/dist/components/dialog/dialog.js';

import '@shoelace-style/shoelace/dist/components/select/select';
import '@shoelace-style/shoelace/dist/components/option/option';

import SlTabGroup from '@shoelace-style/shoelace/dist/components/tab-group/tab-group.js';
import SlDialog from '@shoelace-style/shoelace/dist/components/dialog/dialog.js';

import { registerIconLibrary } from '@shoelace-style/shoelace/dist/utilities/icon-library.js';
registerIconLibrary('my-icons', {
  resolver: (name) => `/wp-content/plugins/afueras_servicios_plugin/assets/bootstrap-icons/${name}.svg`,
  mutator: svg => svg.setAttribute('fill', 'currentColor')
});


function selectedAnexos(anexos: any[]) {
  return html`
  <sl-card class="selected-anexos-wrapper">
    <div slot="header" style="font-size: var(--sl-font-size-large);
      font-weight: var(--sl-font-weight-bold);
      color: var(--global-palette2);">Anexos seleccionados</div>

    <div class="">
    ${anexos.map((anexo)=>(anexo.checked===true)?html`
      <sl-details>
        <div class="details-header" slot="summary">
          <img width="90" height="60" style="object-fit: cover;"
            slot="image"
            src="${anexo.image}"
            alt="${anexo.name}"
          />
          <div><strong>${anexo.name}</strong></div>
        </div>
        <div class="details-default">
        ${anexo.summary}
        </div>
      </sl-details>
    `:null)}
    </div>
  </sl-card>`;
}

function selectedCombos(combos: any[]) {
  return html`
  <sl-card class="selected-anexos-wrapper">
    <div slot="header" style="font-size: var(--sl-font-size-large);
      font-weight: var(--sl-font-weight-bold);
      color: var(--global-palette2);">Combos seleccionados</div>

    <div class="">
    ${combos.map((combo)=>(combo.checked===true)?html`
      <sl-details>
        <div class="details-header" slot="summary">
          <img width="90" height="60" style="object-fit: cover;"
            slot="image"
            src="${combo.image}"
            alt="${combo.name}"
          />
          <div><strong>${combo.name}</strong></div>
        </div>
        <div class="details-default">
        ${combo.summary}
        </div>
      </sl-details>
    `:null)}
    </div>
  </sl-card>`;
}


@customElement('app-pedidos')
export class AppPedidos extends LitElement {

  static get styles() {
    return [
      sharedStyles,
      css`

      #welcomeBar {
        display: flex;
        //justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      #welcomeCard,
      #infoCard {
        padding: 18px;
        padding-top: 0px;
      }

      @media(min-width: 750px) {
        #welcomeCard {
          width: 70vw;
        }
      }


      @media (horizontal-viewport-segments: 2) {
        #welcomeBar {
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-around;
        }

        #welcomeCard {
          margin-right: 64px;
        }
      }

      sl-button.select-button {
        width: 100%;
      }

      sl-button.primary-theme::part(label) {
        margin-right: auto;
      }

      sl-button.primary-theme:hover::part(base) {
        background-color: var(--wp--preset--color--theme-palette-1);
        border-color: var(--wp--preset--color--theme-palette-1);
        color: var(--sl-color-neutral-50);
      }

      sl-button.primary-theme.active::part(base) {
        background-color:  var(--wp--preset--color--theme-palette-1);
        border-color: var(--wp--preset--color--theme-palette-1);
        color: var(--sl-color-neutral-50);
      }

      sl-button.primary-theme.active-dark::part(base) {
        background-color:  var(--wp--preset--color--theme-palette-3);
        border-color: var(--wp--preset--color--theme-palette-3);
        color: var(--sl-color-neutral-50);
      }

      sl-button.primary-theme.active:hover::part(base) {
        background-color: var(--wp--preset--color--theme-palette-2);
        border-color: var(--wp--preset--color--theme-palette-2);
        color: var(--sl-color-neutral-50);
      }

      sl-icon.check-icon {
        font-size: var(--sl-font-size-x-large);
      }

      .card-overview {
        max-width: 500px;
        min-width: 300px;
        margin-bottom: var(--sl-spacing-medium);
        display: flex;
      }

      .card-overview::part(base) {
        justify-content: start !important;
      }

      .card-overview small {
        color: var(--sl-color-neutral-500);
      }

      .card-overview::part(body) {
        margin-bottom: auto;
      }

      .card-overview [slot='footer'] {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .card-overview [slot="image"] {
        max-width: 500px;
        max-height: 333px;
        object-fit: cover;
        aspect-ratio: 3 / 4;
        border-top-left-radius: var(--sl-border-radius-x-large);
        border-top-right-radius: var(--sl-border-radius-x-large);
        /*border-bottom-left-radius: var(--sl-border-radius-x-large);
        border-bottom-right-radius: var(--sl-border-radius-x-large);*/
      }

      .card-overview::part(base) {
        border-top-left-radius: var(--sl-border-radius-x-large);
        border-top-right-radius: var(--sl-border-radius-x-large);
        border-bottom-left-radius: var(--sl-border-radius-x-large);
        border-bottom-right-radius: var(--sl-border-radius-x-large);
        min-width: 300px;
        max-width: 500px;
      }


      .combos-wrapper, .anexos-wrapper {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        justify-content: space-around;
      }

      .details-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
      }

      .selected-combos-wrapper,
      .selected-anexos-wrapper,
      .datos-personales-wrapper { width: 100%; margin-bottom: var(--sl-spacing-medium); }

      .selected-combos-wrapper::part(base),
      .selected-anexos-wrapper::part(base),
      .datos-personales-wrapper::part(base) {
        border-top-left-radius: var(--sl-border-radius-x-large);
        border-top-right-radius: var(--sl-border-radius-x-large);
        border-bottom-left-radius: var(--sl-border-radius-x-large);
        border-bottom-right-radius: var(--sl-border-radius-x-large);
      }
      /*
      .selected-combos-wrapper::part(body),
      .selected-anexos-wrapper::part(body),
      .datos-personales-wrapper::part(body) {
        padding: 0 !important;
      }*/

      sl-details.custom-icons::part(summary-icon) {
        /* Disable the expand/collapse animation */
        rotate: none;
      }

    `];
  }

  // For more information on using properties and state in lit
  // check out this link https://lit.dev/docs/components/properties/
/*
  @property()
  ap_path!: string;
*/
  // Lista de combos y anexos
  @state() pedidos = {pedido: {
    combos: [
       {id: 1, name: "C1 - GLAMPING",
        image: "https://afueras.com.ar/wp-content/uploads/2024/08/FOTO-4-COMBO-GLAMPING-1-1024x738.jpg", checked: false,
        summary: "El glamping es un rincón que se construye al césped, permitiendo que esa área naturaleza se transforme en una experiencia imperdible que dura toda la noche hasta su retiro al día siguiente."
        },
       {id: 2, name: "C2 - PICNIC BOHO",
        image: "https://afueras.com.ar/wp-content/uploads/2024/07/COMBO-PICNIC-ANEXO-JUEGOS-768x1024.jpg", checked: false,
        summary:"El picnic es uno de los rincones más versátiles para acompañar a cualquiera del resto de los combos. Su estilismo enamora por el impacto sensorial que generan la superposición de textiles que proponemos sobre el verde y al viento." },
       {id: 3, name: "C3 - MIX PICNIC LIVING",
        image: "https://afueras.com.ar/wp-content/uploads/2024/07/COMBO-MIX-PICNIC-LIVING2-822x1024.jpg", checked: false,
        summary: "Este Combo respeta las mismas características que el anterior en tanto cantidad de usuarios y superficie a tener disponible para su desplazamiento, pero se destaca por mezclar el mobiliario textil con otro más característico de living como son los bancos, banquetas, pufs y mesas ratonas de mayor altura y diseño que las del picnic que tienen un estilo pallet reciclado y más al ras del césped." },
       {id: 4, name: "C4 - DECO LÚDICO",
        image: "https://afueras.com.ar/wp-content/uploads/2024/07/COMBO-DECO-LUDICO7-1024x1005.jpg", options: [
         {id: 1,  name: "opt 1",  image: "", checked: false},
         {id: 2,  name: "opt 2",  image: "", checked: false},
         {id: 3,  name: "opt 3",  image: "", checked: false},
         {id: 4,  name: "opt 4",  image: "", checked: false},
         {id: 5,  name: "opt 5",  image: "", checked: false},
         {id: 6,  name: "opt 6",  image: "", checked: false},
         {id: 7,  name: "opt 7",  image: "", checked: false},
         {id: 8,  name: "opt 8",  image: "", checked: false},
         {id: 9,  name: "opt 9",  image: "", checked: false},
         {id: 10, name: "opt 10", image: "", checked: false}
       ], checked: false,
        summary: "Este combo está pensado para ambientar un espacio que fusione nuestros recursos de mobiliario y deco en conjunto con los juegos artesanales, los cuales tienen la particularidad de poder dirigirse a distintos grupos en tanto edades o tipos de evento."}
     ],
     anexos: [
       {id: 1,  name: "BELL TENT",                  image: "https://afueras.com.ar/wp-content/uploads/2024/07/ANEXO-BELL-TENT-15-ANOS2-1024x756.jpg", checked: false, summary: "Este anexo se refiere a nuestra carpa campana sola como recurso que puede añadirse y acompañar otras áreas de ambientación que no la incluyan. Ejemplo de ello sería desplazarla junto a mesas picnic para continuar con un sector relax o con una mesa dulce, sectorizando un área lúdica para los más pequeños, como rincón fotográfico, etc."},
       {id: 2,  name: "ARCADA DECO",                image: "https://afueras.com.ar/wp-content/uploads/2024/08/FOTO-2-ARCADA.jpg", checked: false, summary: "Se compone por una estructura en madera que suele sostener una caída de tela, pueden ser arreglos de follaje en seco como pampas Grass o flores naturales, al igual que lámparas textiles de diseño o macramé artesanal. Suele acompañarse con alfombra deco y fanales al piso. Puede cumplir la función de rincón para fotos, detrás de una mesa dulce o como arcada de ingreso a la fiesta."},
       {id: 3,  name: "BARRITA SLOW",               image: "https://afueras.com.ar/wp-content/uploads/2024/07/ANEXO-BARRITA-SLOW-225x300.jpg", checked: false, summary: "Es una barra pequeña de diseño en pallet que solemos ubicar junto a detalles como fanales, alfombra y lámparas de diseño textil. Puede cumplir el rol de mesa dulce, de estación para comida en general o como barra de tragos."},
       {id: 4,  name: "CINEMA",                     image: "https://afueras.com.ar/wp-content/uploads/2024/08/FOTO-1-ANEXO-CINEMA-1024x1007.jpg", checked: false, summary: "Este anexo no incluye servicio técnico que permanezca en el lugar para su uso por lo cual hay requisitos a tener en cuenta para su eficiencia como la luz natural o del ambiente al momento de uso. El cine bajo las estrellas cumple tanto la función de proyección de una película para niños, como de fotos o videos para cualquier tipo de aniversario, de música funcional, o con la proyección de videos de música o para bailar Just Dance y copiar las coreografías más divertidas."},
       {id: 5,  name: "FOGÓN",                      image: "https://afueras.com.ar/wp-content/uploads/2024/07/ANEXO-FOGON6-1024x772.jpg", checked: false, summary: "Alrededor del fuego se reúnen todo tipo de historias, de ceremonia para pedir deseos, para soltar apegos, para meditaciones profundas o cantar en grupo alguno de esos temas que sabemos todos…el calor contagia ese magnetismo y buena vibra."},
       {id: 6,  name: "DORMIS",                     image: "https://afueras.com.ar/wp-content/uploads/2024/08/FOTO-2-ANEXO-DORMIS-916x1024.jpg", checked: false, summary: "Cuando prescindimos del desplazamiento de nuestra carpa campana como contención de las colchonetas para dormir podemos recrear ese área específica a través de un encuadre con alfombras, postes con banderines, luces + deco como colgantes y fanales de piso."},
       {id: 7,  name: "JUEGOS",                     image: "https://afueras.com.ar/wp-content/uploads/2024/07/ANEXO-JUEGOS10-1024x1013.jpg", checked: false, summary: "A veces solo queremos anexar uno o dos de ellos para sumar la conexión y distensión al encuentro."},
       {id: 8,  name: "RUEDA_RULETA DE LA FORTUNA", image: "https://afueras.com.ar/wp-content/uploads/2024/08/foto-1-ruleta-695x1024.jpg", checked: false, summary: "Este mueble de 2 mts de altura tan estético como funcional cumple un rol organizador en más de un tipo de evento: por ejemplo, puede rotularse para sortear premios en algún festejo de local comercial, en un cumpleaños infantil a través de sus originales leyendas distribuir prendas, sorpresas o regalos, como también en una boda puede traer fortuna en algún juego entre invitados, en definitiva, da rienda suelta a la creatividad para su incorporación."},
       {id: 9,  name: "INCLUSIÓN SOBRE LAS MESAS DE VAJILLA, MANTELERIA O CENTROS DE MESA", image: "https://afueras.com.ar/wp-content/uploads/2024/07/cropped-logo-afueras6_512x512-1.png", checked: false, summary: "Pedir presupuesto en función de opciones disponibles y para que cantidad de usuarios."},
       {id: 10, name: "INCLUSIÓN de diseño de PAPELERÍA", image: "https://afueras.com.ar/wp-content/uploads/2024/07/cropped-logo-afueras6_512x512-1.png", checked: false, summary: "En mesas para numerarlas, cada lugar nominado, individuales, cartel de recepción, indicador de ubicaciones de las áreas del evento o en cual mesa cada quien/invitaciones temáticas en papel o virtuales"},
       {id: 11, name: "OPCIONES DE SOUVENIR TEMATICO", image: "https://afueras.com.ar/wp-content/uploads/2024/07/cropped-logo-afueras6_512x512-1.png", checked: false, summary: "Ejemplos: bolsitas de dormir, mantitas, tazas enlozadas, linternas, bolsas tela, almohadones, sombreros paja, botellitas, etc."},
     ]}
   };

  /*
  @state()
  combosUpdated=false;
  */

  @query('#eventoFechaInput')
  eventoFechaInput!: HTMLInputElement;

  @query('#dialogComboAlerta')
  dialogComboAlerta!: SlDialog;

  @query('#dialogAnexoAlerta')
  dialogAnexoAlerta!: SlDialog;

  @query('#tabGroup')
  tabGroup!: SlTabGroup;

  @query('#combosWrapper')
  combosWrapper!: HTMLElement;

  @query('#selectedCombos')
  selectedCombos!: HTMLElement;

  @query('#apPedidos')
  apPedidos!: HTMLInputElement;

  @query('#formPedidosServicios')
  formPedidosServicios!: HTMLFormElement;

  @state()
  formSubmitButtonDisabled: boolean = false;

  constructor() {
    super();
  }

  async firstUpdated() {
    // this method is a lifecycle even in lit
    // for more info check out the lit docs https://lit.dev/docs/components/lifecycle/
    // console.log('This is your home page');
  }


  // Checkea el HTMLFormElement
  checkForm(event: CustomEvent) {

    event.preventDefault();

    if (this.formPedidosServicios && this.formPedidosServicios.checkValidity()) {
      // Deshabilito el boton de submit
      this.formSubmitButtonDisabled=true;
      this.apPedidos.value = JSON.stringify(this.getSelectedItems());

      // const data = serialize(this.formPedidosServicios);
      // console.log(data);

      this.formPedidosServicios.submit();
    }
  }

  getSelectedItems() {
    return {pedido: { combos: this.pedidos.pedido.combos.filter(combo => combo.checked), anexos: this.pedidos.pedido.anexos.filter(anexo => anexo.checked) } }
  }


  render() {
    return html`
      <sl-tab-group id="tabGroup">
        <sl-tab slot="nav" panel="paso1" style="display: none;">Paso 1</sl-tab>
        <sl-tab slot="nav" panel="paso2" style="display: none;">Paso 2</sl-tab>
        <sl-tab slot="nav" panel="paso3" style="display: none;">Paso 3</sl-tab>

        <sl-tab-panel name="paso1">
        <!-- <panel-paso1> -->
          <div style="width: 100%;">
            <div class="card-header" slot="header">
              <div class="title" style="font-size: var(--sl-font-size-x-large);
                  font-weight: 600;
                  padding: var(--sl-spacing-medium) 0;
                  text-align: center;
                  color: var(--global-palette4);">PASO 1 - SELECCIONAR COMBOS</div>
            </div>

            <div id="combosWrapper" class="wrapper">
              ${this.pedidos.pedido.combos.map((combo)=> html`
                  <sl-card class="card-overview panel">
                    <img
                      slot="image"
                      src="${combo.image}"
                      alt="${combo.name}"
                    />

                    <sl-details>
                      <div slot="summary"><strong>${combo.name}</strong></div>
                      ${combo.summary}
                    </sl-details>
                    
                    <div slot="footer">
                      <sl-animation name="rubberBand" duration="1000" iterations="1">
                        <sl-button variant="default" class="primary-theme select-button"
                                  size="medium"
                                  data-combo-id="${combo.id}"
                                  data-checked="0"
                                  @click="${ this.selectCombo }">
                          <sl-icon name="circle" library="my-icons" slot="suffix" class="check-icon"></sl-icon>
                          <div>Seleccionar</div>
                        </sl-button>
                      </sl-animation>
                    </div>
                  </sl-card>
              `)}
            </div>

            <div slot="footer" style="display: flex; justify-content: right;">
              <sl-button variant="default" class="primary-theme active-dark"
                         @click="${ () => this.shouldNavigateTo("paso2") }" size="medium">
                <sl-icon slot="suffix" library="my-icons" name="arrow-right"></sl-icon>
                Siguiente
              </sl-button>
            </div>
          </div>
          <!-- </panel-paso1> -->
        </sl-tab-panel>
        <!-- =================  -->
        <!-- ==== PANEL 2 ====  -->
        <!-- =================  -->
        <sl-tab-panel name="paso2">
          <!-- <panel-paso2> -->
          <div style="width: 100%;">

            <div class="seleccionados-wrapper" slot="header">
              <div class="title" style="font-size: var(--sl-font-size-x-large);
                  font-weight: 600;
                  padding: var(--sl-spacing-medium) 0;
                  text-align: center;
                  color: var(--global-palette4);">PASO 2 - SELECCIONAR ANEXOS</div>

                ${selectedCombos(this.pedidos.pedido.combos)}
            </div>

            <!-- -->
            <div id="anexosWrapper" class="wrapper">
              ${this.pedidos.pedido.anexos.map((anexo)=> html`
                  <sl-card class="card-overview panel">
                    <img
                      slot="image"
                      src="${anexo.image}"
                      alt="${anexo.name}"
                    />
                    
                    <sl-details>
                      <div slot="summary"><strong>${anexo.name}</strong></div>
                      ${anexo.summary}
                    </sl-details>
                    <!--
                    <sl-details class="custom-icons">
                      <sl-icon name="plus-circle" library="my-icons" slot="expand-icon"></sl-icon>
                      <sl-icon name="dash-circle" library="my-icons" slot="collapse-icon"></sl-icon>
                      <div slot="summary"><strong>${anexo.name}</strong></div>
                      ${anexo.summary}
                    </sl-details>
                    -->
                    <div slot="footer">
                      <sl-animation name="rubberBand" duration="1000" iterations="1">
                        <sl-button variant="default" class="primary-theme select-button"
                                  size="medium"
                                  data-anexo-id="${anexo.id}"
                                  data-checked="0"
                                  @click="${ this.selectAnexo }">
                          <sl-icon name="circle" library="my-icons" slot="suffix" class="check-icon"></sl-icon>
                          <div>Seleccionar</div>
                        </sl-button>
                      </sl-animation>
                    </div>
                  </sl-card>
              `)}
            </div>

            <div slot="footer" style="display: flex; justify-content: space-between;">
              <sl-button variant="default" outline class="primary-theme"
                         @click="${()=>{ this.shouldNavigateTo('paso1'); }}" size="medium">
                <sl-icon slot="prefix" library="my-icons" name="arrow-left"></sl-icon>
                Volver
              </sl-button>
              <sl-button variant="default" class="primary-theme active-dark"
                         @click="${()=>{ this.shouldNavigateTo('paso3'); }}" size="medium">
                <sl-icon slot="suffix" library="my-icons" name="arrow-right"></sl-icon>
                Siguiente
              </sl-button>
            </div>
          </div>
          <!-- </panel-paso2> -->
        </sl-tab-panel>
        <!-- =================  -->
        <!-- ==== PANEL 3 ====  -->
        <!-- =================  -->
        <sl-tab-panel name="paso3">
          <!-- <panel-paso3> -->
          <div style="width: 100%;">

            <div class="seleccionados-wrapper" slot="header">
              <div class="title" style="font-size: var(--sl-font-size-x-large);
                  font-weight: 600;
                  padding: var(--sl-spacing-medium) 0;
                  text-align: center;
                  color: var(--global-palette4);">PASO 3 - COMPLETA EL FORMULARIO</div>

                ${selectedCombos(this.pedidos.pedido.combos)}

                ${selectedAnexos(this.pedidos.pedido.anexos)}
            </div>



            <!-- -->
            <form id="formPedidosServicios" method="post" @submit="${this.checkForm}">
            <sl-card class="datos-personales-wrapper">
              <div slot="header" style="font-size: var(--sl-font-size-large);
                font-weight: var(--sl-font-weight-bold);
                color: var(--global-palette2);">Datos Personales</div>
                <sl-input name="name" label="Nombre y Apellido" required placeholder="Nombre y Apellido"></sl-input>
                <br />
                <sl-input name="email" type="email" label="Correo electrónico" required placeholder="Un correo electrónico de contacto"></sl-input>
                <br />
                <sl-select label="Cliente" name="cliente" required placeholder="Particular u Organizador de eventos">
                  <sl-option value="particular">Particular</sl-option>
                  <sl-option value="organizador">Organizador de eventos</sl-option>
                </sl-select>
                <br>
                <sl-input name="instagram" label="Instagram" placeholder="Instagram"></sl-input>
                <br>
                <sl-input name="whatsapp" label="WhatsApp" placeholder="WhatsApp"></sl-input>
                <br>
                <span>Fecha del evento:</span>
                <br>
                <date-selector @date-selected="${this.dateSelected}"></date-selector>
                <br>
                <sl-input name="evento_horario" label="Horarios" required placeholder="El horario del evento"></sl-input>
                <br>
                <sl-input name="evento_ubicacion" label="Ubicación" required placeholder="La ubicación del evento"></sl-input>
                <br>
                <sl-input name="evento_tipo" label="Tipo de evento" required placeholder="Cumpleñanos, boda, cierre de año empresarial, etc:"></sl-input>
                <br>
                <sl-input name="evento_participantes" label="Participantes" required placeholder="Cantidad participantes"></sl-input>

                <input type="hidden" name="evento_fecha" id="eventoFechaInput">
                <input type="hidden" name="item"         id="apPedidos">
                <input type="hidden" name="quantity"          value="1">
                <input type="hidden" name="ap_form_submitted" value="true">
                <br>
                <div slot="footer" style="display: flex; justify-content: space-between;">
                  <sl-button variant="default" outline class="primary-theme"
                            @click="${()=>{ this.shouldNavigateTo('paso2'); }}" size="medium">
                    <sl-icon slot="prefix" library="my-icons" name="arrow-left"></sl-icon>
                    Volver
                  </sl-button>
                  <sl-button
                    type="submit"
                    variant="default"
                    class="primary-theme active-dark"
                    ?disabled=${this.formSubmitButtonDisabled}>Enviar</sl-button>
                </div>
            </sl-card>
            </form>

          </div>
          <!-- </panel-paso3> -->
        </sl-tab-panel>
      </sl-tab-group>


      <sl-dialog label="Atención" id="dialogComboAlerta" class="dialog-overview">
        ¡Atención, para continuar primero debes elegir uno o más combos!
        <sl-button slot="footer" variant="primary" @click="${ () => { this.dialogComboAlerta.hide(); } }">Cerrar</sl-button>
      </sl-dialog>

      <sl-dialog label="Atención" id="dialogAnexoAlerta" class="dialog-overview">
        ¡Atención, para continuar primero debes elegir uno o más anexos!
        <sl-button slot="footer" variant="primary" @click="${ () => { this.dialogAnexoAlerta.hide(); } }">Cerrar</sl-button>
      </sl-dialog>
    `
  }

  dateSelected(event: CustomEvent) {
    this.eventoFechaInput.value = event.detail.dateSelected;
  }

  shouldNavigateTo(paso: string) {
    switch(paso){
      case("paso1"):
        this.tabGroup.show('paso1');
        this.smoothScrollTo();
      break;
      case("paso2"):
        if (this.isAnyComboChecked()) {
          this.tabGroup.show('paso2');
          this.smoothScrollTo();
        } else {
          this.dialogComboAlerta.show();
        }
      break;
      case("paso3"):
        if (this.isAnyAnexoChecked()) {
          this.tabGroup.show('paso3');
          this.smoothScrollTo();
        } else {
          this.dialogAnexoAlerta.show();
        }
      break;
    }
  }

  smoothScrollTo() {
    let element!: HTMLElement;
    /*
    if (this.renderRoot.querySelector(target)) {
      element = this.renderRoot.querySelector(target)!;
      console.log("scrollIntoView: "+target, element);
      console.log("offsetTop: ", element.offsetTop);
      element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
    */

    if (document.getElementById('afuerasServicios')) {
      element = document.getElementById('afuerasServicios')!;
      // console.log("scrollIntoView: "+target, element);
      // console.log("offsetTop: ", element.offsetTop);
      element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }

  }

  isAnyComboChecked() {
    const combos = this.pedidos.pedido.combos;
    // console.log(typeof combos);
    // console.log("combos: ", combos, typeof combos);
    let is_checked = false;
    for (let combo of combos) {
      // console.log("combo: ", combo);
      if (combo.checked === true) {
        // console.log( `[${combo.id}] - ${combo.name}` );
        is_checked = true;
      }
    }
    return is_checked;
  }

  isAnyAnexoChecked() {
    const anexos = this.pedidos.pedido.anexos;
    console.log(typeof anexos);
    // console.log("combos: ", combos, typeof combos);
    let is_checked = false;
    for (let anexo of anexos) {
      // console.log("combo: ", combo);
      if (anexo.checked === true) {
        // console.log( `[${combo.id}] - ${combo.name}` );
        is_checked = true;
      }
    }
    return is_checked;
  }

  selectCombo(event: Event) {
    const comboButton                = event.currentTarget as HTMLButtonElement;
    const comboButtonAnimationParent = comboButton.parentElement;
    // HTMLCollection
    const comboButtonSlIconChild     = comboButton.children[0];
    const comboButtonSpanText        = comboButton.children[1];

    const comboId = comboButton.dataset.comboId;
    // console.log("comboId: ", comboId);
    if (comboButton.dataset.checked=="0") {
      this.checkCombo(comboId);
      comboButton.dataset.checked="1";
      // comboButton.setAttribute('variant', "success");
      comboButton.classList.add('active');
      comboButtonAnimationParent?.setAttribute("play", ""); // Boolean
      comboButtonSlIconChild.setAttribute("name", "check-circle");
      comboButtonSpanText.innerHTML="Seleccionado";
    } else {
      this.unCheckCombo(comboId);
      comboButton.dataset.checked="0";
      // comboButton.setAttribute('variant', "default");
      comboButton.classList.remove('active');
      comboButtonAnimationParent?.setAttribute("play", ""); // Boolean
      comboButtonSlIconChild.setAttribute("name", "circle");
      comboButtonSpanText.innerHTML="Seleccionar";
    }

  }

  selectAnexo(event: Event) {
    const anexoButton                = event.currentTarget as HTMLButtonElement;
    const anexoButtonAnimationParent = anexoButton.parentElement;
    // HTMLCollection
    const anexoButtonSlIconChild     = anexoButton.children[0];
    const anexoButtonSpanText        = anexoButton.children[1];

    const anexoId = anexoButton.dataset.anexoId;
    // console.log("anexoId: ", anexoId);
    if (anexoButton.dataset.checked=="0") {
      this.checkAnexo(anexoId);
      anexoButton.dataset.checked="1";
      // anexoButton.setAttribute('variant', "success");
      anexoButton.classList.add('active');
      anexoButtonAnimationParent?.setAttribute("play", ""); // Boolean
      anexoButtonSlIconChild.setAttribute("name", "check-circle");
      anexoButtonSpanText.innerHTML="Seleccionado";
    } else {
      this.unCheckAnexo(anexoId);
      anexoButton.dataset.checked="0";
      // anexoButton.setAttribute('variant', "default");
      anexoButton.classList.remove('active');
      anexoButtonAnimationParent?.setAttribute("play", ""); // Boolean
      anexoButtonSlIconChild.setAttribute("name", "circle");
      anexoButtonSpanText.innerHTML="Seleccionar";
    }
  }

  checkCombo(comboId: string | undefined) {
    if (comboId) {
      const combos = this.pedidos.pedido.combos;
      // console.log("combos: ", combos, typeof combos);
      for (let combo of combos) {
        // console.log("combo: ", combo);
        if (combo.id == parseInt(comboId)) {
          // console.log( `[${combo.id}] - ${combo.name}` );
          combo.checked=true;
        }
      }
      this.pedidos = { ...this.pedidos};
      // console.log("checkcombo:", combos);
    }
  }

  unCheckCombo(comboId: string | undefined) {
    if (comboId) {
      const combos = this.pedidos.pedido.combos;
      // console.log("combos: ", combos, typeof combos);
      for (let combo of combos) {
        // console.log("combo: ", combo);
        if (combo.id == parseInt(comboId)) {
          // console.log( `[${combo.id}] - ${combo.name}` );
          combo.checked=false; // UnChecked
        }
      }
      this.pedidos = { ...this.pedidos};
      // console.log("uncheckcombo:", combos);
    }
  }

  checkAnexo(anexoId: string | undefined) {
    if (anexoId) {
      const anexos = this.pedidos.pedido.anexos;
      // console.log("anexos: ", anexos, typeof anexos);
      for (let anexo of anexos) {
        // console.log("anexo: ", anexo);
        if (anexo.id == parseInt(anexoId)) {
          // console.log( `[${anexo.id}] - ${anexo.name}` );
          anexo.checked=true;
        }
      }
      this.pedidos = { ...this.pedidos};
      // console.log("checkAnexo:", anexos);
    }
  }

  unCheckAnexo(anexoId: string | undefined) {
    if (anexoId) {
      const anexos = this.pedidos.pedido.anexos;
      // console.log("anexos: ", anexos, typeof anexos);
      for (let anexo of anexos) {
        // console.log("anexo: ", anexo);
        if (anexo.id == parseInt(anexoId)) {
          // console.log( `[${anexo.id}] - ${anexo.name}` );
          anexo.checked=false; // UnChecked
        }
      }
      this.pedidos = { ...this.pedidos};
      // console.log("uncheckanexo:", anexos);
    }
  }
}