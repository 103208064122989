import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

// import './pages/app-paso-uno';
import './pages/app-pedidos';
import './components/header';
import './styles/global.css';

@customElement('app-index')
export class AppIndex extends LitElement {
  static get styles() {
    return css`
      main {
        padding: 0px !important;
      }

      #routerOutlet > * {
        width: 100% !important;
      }

      #routerOutlet > .leaving {
        animation: 600ms fadeOut ease-in-out;
      }

      #routerOutlet > .entering {
        animation: 600ms fadeIn linear;
      }

      @keyframes fadeOut {
        from {
          opacity: 1;
        }

        to {
          opacity: 0;
        }
      }

      @keyframes fadeIn {
        from {
          opacity: 0.2;
        }

        to {
          opacity: 1;
        }
      }
    `;
  }

  constructor() {
    super();
  }

  firstUpdated() {
    // this method is a lifecycle even in lit
    // for more info check out the lit docs https://lit.dev/docs/components/lifecycle/

  }

  render() {
    return html`
      <div>
        <main>
          <app-pedidos></app-pedidos>
        </main>
      </div>
    `;
  }
}
